import * as React from "react";
import ReactDOM from "react-dom";

export interface PortalProps {
  children: React.ReactNode;
  top: number;
}

const portalRoot =
  typeof document !== `undefined` ? document.getElementById("portal") : null;

class Portal extends React.Component<PortalProps> {
  el;
  constructor(props) {
    super(props);
    this.el =
      typeof document !== `undefined` ? document.createElement("div") : null;
    this.el.setAttribute("role", "dialog");
    this.el.setAttribute("aria-modal", "true");
    this.el.style.position = "absolute";
    this.el.style.top = `${this.props.top}px`;
    this.el.style.height = "100vh";
    this.el.style.zIndex = "100";
  }

  componentDidMount() {
    portalRoot?.appendChild(this.el);
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    portalRoot?.removeChild(this.el);
    document.body.style.overflow = "unset";
  }

  render() {
    const { children } = this.props;
    if (this.el) {
      return ReactDOM.createPortal(children, this.el);
    } else {
      return null;
    }
  }
}

export default Portal;
