import * as React from "react";
import { useText } from "../../common/hooks";
import { defaultTheme } from "../../styles/theme";
import Portal from "../portal/Portal";
import { Align, Title, Text, Button } from "../UI";
import { ErrorModalBackdrop, ErrorModalContainer } from "./ErrorModal.styled";

interface ErrorModalProps {
  top: number;
}

export const ErrorModal = React.forwardRef<any, ErrorModalProps>(
  ({ top }, ref) => {
    const { text } = useText();
    const [modalVisible, setModalVisible] = React.useState(false);

    React.useImperativeHandle(ref, () => {
      return {
        openModal: () => handleOpen(),
        closeModal: () => closeModal(),
      };
    });

    const handleOpen = () => {
      setModalVisible(true);
    };
    const closeModal = () => {
      setModalVisible(false);
    };

    if (modalVisible) {
      return (
        <Portal top={top}>
          <ErrorModalBackdrop onClick={closeModal} />
          <ErrorModalContainer>
            <Align direction="column" align="center" justify="space-around">
              <Title size="large" color={defaultTheme.colors.green} bold>
                {text("contact.errorModal.title")}
              </Title>
              <Align direction="column" align="center" justify="center">
                <Text size="medium" color={defaultTheme.colors.green}>
                  {text("contact.errorModal.contact")}
                </Text>
                <Text
                  size="medium"
                  color={defaultTheme.colors.green}
                  spacing="0"
                >
                  <b>email: </b>contact@geeknauts.com
                </Text>
                <Text
                  size="medium"
                  color={defaultTheme.colors.green}
                  spacing="0"
                >
                  <b>{text("contact.errorModal.phone")} </b> +48 662 576 765
                </Text>
              </Align>
              <Button title="Ok" onPress={closeModal} />
            </Align>
          </ErrorModalContainer>
        </Portal>
      );
    }
    return null;
  }
);
