import styled from "styled-components";
import { respondTo } from "../../styles/respondTo";
import { defaultTheme } from "../../styles/theme";

export const ErrorModalBackdrop = styled.div`
  background: ${defaultTheme.colors.white};
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
`;

export const ErrorModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 40%;
  background-color: ${defaultTheme.colors.brandBlack};
  border-radius: ${defaultTheme.borderRadius.medium};
  padding: ${defaultTheme.spacing.s};
  box-shadow: ${defaultTheme.shadow.wide};
  font-family: Raleway;

  ${respondTo.desktop`
  width: 50%;
   `};
  ${respondTo.tablet`
  width: 60%;
  `};
  ${respondTo.mobile`
  width: 80%;
  `};
`;
